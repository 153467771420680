import { PrivateRoute } from "components/PrivateRoute";
import { PublicRoute } from "components/PublicRoute";
import { Login } from "pages/Login";
import Logout from "pages/Logout";
//import { TestScrollbar } from "pages/TestScrollbar";
import { lazy, useEffect, useLayoutEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { MainMenuProfile } from "components/MainMenu/MainMenuProfile";
import { MainMenuBalance } from "components/MainMenu/MainMenuBalance";
//import { MainMenuTemplates } from "components/MainMenu/MainMenuTemplates";
import { Layout } from "pages/Layout";

import { useTranslation } from "react-i18next";
import { useGetLanguage } from 'hooks/useGetLanguage'
import setSalesiqZohoScript from "utils/setSalesiqZohoScript";
import { Registration } from "pages/Registration";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";

// const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Quiz = lazy(() => import("./pages/Quiz"));
const QuizDesign = lazy(() => import("./pages/QuizDesign"));
const ProfileLayout = lazy(() => import("pages/ProfileLayout"));
const QuizLayout = lazy(() => import("pages/QuizLayout"));
const ReferralSystem = lazy(() => import("pages/ReferralSystem"));
const Leads = lazy(() => import("pages/Leads/index"));
const Balance = lazy(() => import("pages/Balance"));
const MyRate = lazy(() => import("pages/MyRate"));
const Projects = lazy(() => import("pages/Projects"));
const Templates = lazy(() => import("pages/Templates"));
const Integration = lazy(() => import("pages/Integration"));
const QuizSettings = lazy(() => import("pages/QuizSettings"));
const Courses = lazy(() => import("pages/Courses"));
const ProfileSettingLayout = lazy(() => import("pages/ProfileSettingsLayout"));
const Profile = lazy(() => import("pages/Profile"));
const Portfolio = lazy(() => import("pages/Portfolio"));
const Rating = lazy(() => import("pages/Rating"));
const Settings = lazy(() => import("pages/Settings"));
const SupportCenter = lazy(() => import("pages/SupportCenter"));
const Review = lazy(() => import("pages/Review"));
const QuizResults = lazy(() => import("pages/QuizResults"));
const QuizFormContacts = lazy(() => import("pages/QuizFormContacts"));
const QuizAddition = lazy(() => import("pages/QuizAddition"));
const QuizConfig = lazy(() => import("pages/QuizConfig"));
const TemplateLayout = lazy(() => import("pages/TemplateLayout"));
const CopyTemplate = lazy(() => import("pages/CopyTemplate"));


function App() {
  const { i18n } = useTranslation();
  const language = useGetLanguage()

  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    setSalesiqZohoScript(language)
  }, [language])

  return (
    <Routes>
      <Route path="/login" element={<PublicRoute component={<Login />} />} />
      <Route path="/registration" element={<PublicRoute component={<Registration />} />} />
      <Route path="/forgot-password" element={<PublicRoute component={<ForgotPassword />} />} />
      <Route path="/reset-password/:token" element={<PublicRoute component={<ResetPassword />} />} />
      <Route
        path="/"
        element={<PrivateRoute component={<Layout />} redirectTo="/login" />}
      >
        <Route path="/" element={<ProfileLayout />}>
          <Route path="" element={<Projects />} />
          <Route path="referral-system" element={<ReferralSystem />} />
          <Route path="leads" element={<Leads />} />
          <Route path="certificate" element={<></>} />
          <Route path="settings" element={<Settings />} />
          <Route path="support" element={<SupportCenter />} />
          <Route path="review" element={<Review />} />
          <Route path="logout" element={<Logout />} />
        </Route>

        <Route
          path="courses"
          element={<ProfileLayout mainMenu={<MainMenuProfile />} />}
        >
          <Route path="" element={<Courses />} />
          <Route path="profile" element={<ProfileSettingLayout />}>
            <Route path="" element={<Profile />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="rating" element={<Rating />} />
          </Route>
        </Route>

        <Route
          path="balance"
          element={<ProfileLayout mainMenu={<MainMenuBalance />} />}
        >
          <Route path="" element={<Balance />} />
          <Route path="my-rate" element={<MyRate />} />
        </Route>

        <Route path="/" element={<TemplateLayout />}>
          <Route path="templates/:projectId" element={<Templates />} />
          <Route path="copy-template/:quizId" element={<CopyTemplate />} />
        </Route>

        <Route path="quiz/:quizId" element={<QuizLayout />}>
          <Route path="questions" element={<Quiz />} />
          <Route path="design-settings" element={<QuizDesign />} />
          <Route path="integration" element={<Integration />} />
          <Route path="settings" element={<QuizSettings />} />
          <Route path="results" element={<QuizResults />} />
          <Route path="form-contacts" element={<QuizFormContacts />} />
          <Route path="addition" element={<QuizAddition />} />
          <Route path="config-action" element={<QuizConfig />} />
          <Route path="" element={<Navigate to="questions" />} />
        </Route>
        {/* <Route path="" element={<Home />} />
        <Route path="projects" element={<Home />} />
        <Route path="project" element={<Home />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
