const setSalesiqZohoScript = (language: string) => {
    if (language === "uk" || language === "ru") {
        if (!document.getElementById("zsiqscript")) {
            const script1 = document.createElement("script");
            script1.innerHTML =
                "window.$zoho=window.$zoho || {}; $zoho.salesiq=$zoho.salesiq||{ready:function(){}}";
            document.head.appendChild(script1);

            const script2 = document.createElement("script");
            script2.id = "zsiqscript";
            script2.src =
                "https://salesiq.zohopublic.eu/widget?wc=siq084a7d55fca478ab4c2a5d88bae97de948ed10f22189685107e392b1427e7eb5";
            script2.defer = true;
            document.head.appendChild(script2);

        } else {
            if ((window as any).$zoho && (window as any).$zoho.salesiq) {
                (window as any).$zoho.salesiq.floatbutton.visible("show")
            }
        }
    } else {
        if ((window as any).$zoho && (window as any).$zoho.salesiq) {
            (window as any).$zoho.salesiq.floatbutton.visible("hide")
        }

    }
};

export default setSalesiqZohoScript;